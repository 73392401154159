import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { Heading, Text } from "~/components/common/Typography"
import HeroVideo from "~/components/page/HeroVideo"
import { Box, Flex } from "reflexbox"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import { mq } from "~/components/common/theme"
import { InfoboxDetailed } from "~/components/page/Infobox"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 2, mySubpage = 5 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "3.6HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graph1: file(name: { eq: "3.6SETUPASST" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graph2: file(name: { eq: "3.6WELCOMEPARTY" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, graph1, graph2 } = data

  const graphics = [
    {
      header: "The set-up assistant:",
      text:
        "As a coach, you don’t always have time to set up all of your drills before practice begins. Try recruiting a player as a set-up assistant. This not only gives her a meaningful role, but also gives you the opportunity to maximize your time with the team.",
      graphic: graph1,
    },
    {
      header: "The welcome party leader:",
      text:
        "Cultivating relationships can be as simple as a “hello.” Sometimes, coaches are too busy to greet each player when they arrive. Try designating a player as the welcome party leader who can help her teammates feel acknowledged for showing up while also building valuable athlete-to-athlete relationships.",
      graphic: graph2,
    },
  ]

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Traditions can also bridge the gap to another powerful element of team culture: creating more opportunities for real contribution"
        image={hero}
        text={
          "There are many positive effects of real contribution that you should prioritize throughout the program to help your girls feel valuable to the team. "
        }
        bg={"white"}
      />

      <InfoboxDetailed
        as={"section"}
        headerMaxWidth={"desktop"}
        headerText={"In practice"}
        py={6}
      >
        <Box px={3} maxWidth={"mediumContent"} mx={"auto"}>
          <Text>
            Research has shown that traumatic experiences can have a biological
            effect on kids, but contributing to something positive and feeling
            like they matter can help reduce some of the negative impacts of
            this stress and trauma.<Source>23</Source>We will cover more on
            trauma in the next lesson.
          </Text>
          <Text>
            You may only have a limited time during each practice to run through
            drills, but creating opportunities within this structure for your
            girls to take on an active role in the success of the team can help
            girls overcome adversity.
          </Text>
          <Heading color={"grey-text"} as={"h2"}>
            EXAMPLE ROLES
          </Heading>
        </Box>
        <Flex
          maxWidth={"desktop"}
          mx={"auto"}
          justifyContent={"center"}
          alignItems={"start"}
          mb={5}
          mt={5}
          css={css`
            flex-wrap: wrap;
          `}
        >
          {graphics.map((item, index) => (
            <GraphicWithText
              image={item.graphic}
              width={["100%", "50%"]}
              px={6}
              key={index}
              mq={mq({
                width: ["90%", "100%"],
              })}
            >
              <Text my={0}>
                <span
                  css={css`
                    font-family: "AdineuePROBlackWeb";
                    text-transform: uppercase;
                    font-size: 1.5em;
                  `}
                >
                  {item.header}
                </span>
              </Text>
              <Text mt={0}>{item.text}</Text>
            </GraphicWithText>
          ))}
        </Flex>
        <Box px={3} maxWidth={"mediumContent"} mx={"auto"}>
          <Text>
            While some of these seem like small tasks, they are intentional jobs
            that ensure girls feel they, as team members, are contributing a
            real benefit to the success of the program.
          </Text>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        as={"section"}
        headerMaxWidth={"desktop"}
        headerText={"It all goes back to building a safe space."}
        pb={6}
        pt={0}
      >
        <Box px={3} maxWidth={"mediumContent"} mx={"auto"}>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
          >
            You may only have a limited time during each practice to run through
            drills, but creating opportunities within this structure for your
            girls to take on an active role in the success of the team can help
            girls overcome trauma.
          </Text>
        </Box>
      </InfoboxDetailed>

      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 2, 5)
